<template>
  <div v-if="store.state.webPage==='true'">
    <!--      sun-明-->
    <home-view_sun v-if="store.state.theme==='sun'"></home-view_sun>
    <!--      moon-暗-->
    <home-view_moon v-if="store.state.theme==='moon'"></home-view_moon>
  </div>
  <div v-else>
    <div v-if="store.state.webPage==='false'">
      <home-view_sun_h5 v-if="store.state.theme==='sun'"></home-view_sun_h5>
      <home-view_moon_h5 v-else></home-view_moon_h5>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import HomeView_sun from "@/views/HomeView_sun.vue";
import HomeView_moon from "@/views/HomeView_moon.vue";
import HomeView_sun_h5 from "@/views/homeView_h5/homeView_sun_h5.vue";
import HomeView_moon_h5 from "@/views/homeView_h5/homeView_moon_h5.vue";


const store = useStore();


</script>

<style lang="less">

</style>
