<template>
  <div class="css-1co2tbr">
    <img src="@/assets/image/home_bg.png">
    <div class="css-1fjb92j">
      <div class="css-1cmazxv">
        <div>
          <div class="MainTitleContent css-3l1e46">{{$t("message.title")}}</div>
          <div class="css-7g46bb MainTitleDescContent" style="margin-bottom: 44px;margin-top: 36px;">
            {{$t("message.title_dec_1")}}
          </div>
          <div class="css-7g46bb MainTitleDescContent" style="margin-bottom: 40px;">
            {{$t("message.title_dec_2")}}
          </div>
          <a href="#downloadContent">
            <button class="css-5jykr7">
              <img src="@/assets/image/download.svg">
              {{$t("message.downLoadAchat")}}
            </button>
          </a>
        </div>
        <div class="css-2rff7o ">
          <img src="@/assets/image/theme/top_img_desk@3x.png" class="main-pc">
          <img src="@/assets/image/theme/top_img_phone@3x.png" class="main-phone">
          <div class="message-1 quto-message">
            <span>{{$t("message.quto_message_1")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_1_time")}}</span>
          </div>
          <div class="message-2 quto-message">
            <span>{{$t("message.quto_message_2")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_2_time")}}</span>
          </div>
          <div class="message-3 quto-message">
            <span>{{$t("message.quto_message_3")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_3_time")}}</span>
          </div>
          <div class="message-4 quto-message">
            <span>{{$t("message.quto_message_4")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_4_time")}}</span>
          </div>
          <div class="quto-message message-5">
            <span>{{$t("message.quto_message_5")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_5_time")}}</span>
          </div>
          <div class="quto-message message-6">
            <span>{{$t("message.quto_message_6")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_6_time")}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="MuiBox-root css-6ns9pe"></div>
  <application-traite></application-traite>
  <div class="Download css-n6tcva " id="downloadContent">
    <div class="MuiContainer-root MuiContainer-maxWidthLg DownloadContainer css-1c0oca0">
      <div class="DownloadTitle MuiBox-root css-1d5ftub">
        <span class="BoldFontTitleStyle css-1xgifju light-theme">
          {{$t("message.downLoadAchat")}}
        </span>
      </div>
      <div class="content-center MuiBox-root css-148e9u8">
        <div class="MuiBox-root">
          <div class="MuiBox-root css-6e4k46">
            <div class="MuiBox-root css-22hgpa">
              <img src="@/assets/image/home/down_img_phone@3x.png" alt="">
            </div>
          </div>
          <div class="download-button-content MuiBox-root css-1tnr7w">
            <button class=" MuiButton-containedPrimary   MuiButtonBase-root download-button css-15ezw6l" tabindex="0" type="button" @click="downloadAchatAndro">{{$t("message.android")}}</button>
<!--            <a-tooltip class="dark_theme" placement="top" color="#fff">-->
<!--              <template #title>-->
<!--                <div style="color: #252b4d;" >-->
<!--                  <div style="font-size: 18px;text-align: center;margin: 6px 0 12px 0;">{{$t("message.scalCode")}}</div>-->
<!--                  <div>-->
<!--                    <img src="@/assets/image/QRCode.png">-->
<!--                    <img :src="store.state.imgUrl">-->
<!--                  </div>-->
<!--                </div>-->
<!--              </template>-->
              <button class=" MuiButton-containedPrimary   MuiButtonBase-root download-button css-15ezw6l" tabindex="0" type="button" @click="downloadAchatIos()">{{$t("message.iPhone")}}</button>
<!--            </a-tooltip>-->
          </div>
        </div>
        <div class="MuiBox-root ">
          <div class="down-content-image MuiBox-root css-6e4k46">
            <div class="down-img MuiBox-root css-18nidnv">
              <img src="@/assets/image/home/down_img_mac@3x.png" alt="">
            </div>
          </div>
          <div class="MuiBox-root css-1a14rma">
            <button class=" MuiButton-containedPrimary   MuiButtonBase-root download-button css-15ezw6l" tabindex="0" type="button" @click="downloadAchatMac">
              {{$t("message.Mac")}}
            </button>
          </div>
          <div class="download-desc MuiBox-root css-1qk3f4n">
<!--            <a href="javascript:;">下载Mac（M1）</a>-->
          </div>
          <div class="download-desc MuiBox-root css-1lyfx7r">{{$t("message.scalLogin")}}</div>
          <div class="MuiBox-root css-1g31uvb light-theme">{{$t("message.ortherType")}}</div>
          <div class="download-desc MuiBox-root css-1qk3f4n"><span @click="downloadAchatWind">{{$t("message.Windows")}}</span></div>
        </div>
      </div>
    </div>
  </div>
  <div class="css-ky9xmf">
    <img src="@/assets/image/moon_icon.svg" style="width: 100%;height: 100%;transform: scale(1.3);" v-if="store.state.theme==='sun'" @click="changeTheme('moon')">
    <img src="@/assets/image/sun_icon.png" style="width: 100%;height: 100%;transform: scale(1.3);" v-else @click="changeTheme('sun')">
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import ApplicationTraite from "@/components/applicationTraite";
import {downloadAchatMac,downloadAchatWind,downloadAchatAndro,downloadAchatIos} from "@/utils/downLoadApp";




const store = useStore();

function changeTheme(val){
  store.state.theme=val
}


</script>

<style lang="less">

</style>
