<template>
<div class="footerContent css-11lpknc">
  <div class="footerContainer css-1c0oca0">
    <div class="css-18v1xfh">
      <div class=" MuiGrid-item  css-ezggq0">
        <div class="css-1fcsmgl">
          <div class="css-84izha">
            <img src="@/assets/image/logo-icon.png">
          </div>
          <div style="margin-right: 20px;width: 250px;padding-left: 16px;" class="css-j7qwjs">
            <span style="font-weight: 600;margin-bottom: 4px;" class="css-aqo0mr light-theme">Chat Base</span>
            <span class="css-llfahp">{{$t("message.bottom_title")}}</span>
          </div>
        </div>
<!--        <span class="css-11k05a3">联系我们：Postmaster@mail.antmessager.com</span>-->
      </div>
      <div class=" MuiGrid-item  css-1kl3a5h">
        <div class="css-j7qwjs">
          <span class="css-aqo0mr light-theme">{{$t("message.followMe")}}</span>
          <button class=" MuiButton-text  MuiButtonBase-root css-1w126ji" tabindex="0" type="button">{{$t("message.menu_1")}}</button>
          <button class=" MuiButton-text  MuiButtonBase-root css-1w126ji" tabindex="0" type="button" @click="clickMenu">{{$t("message.menu_5")}}</button>
<!--          <button class=" MuiButton-text  MuiButtonBase-root css-1w126ji" tabindex="0" type="button">{{$t("message.menu_2")}}</button>-->
<!--          <button class=" MuiButton-text  MuiButtonBase-root css-1w126ji" tabindex="0" type="button">{{$t("message.menu_3")}}</button>-->
<!--          <button class=" MuiButton-text  MuiButtonBase-root css-1w126ji" tabindex="0" type="button">{{$t("message.menu_4")}}</button>-->
        </div>
      </div>
      <div class=" MuiGrid-item  css-1kl3a5h">
        <div class="MuiBox-root css-j7qwjs">
          <span class="css-aqo0mr light-theme">{{$t("message.downLoadAchat")}}</span>
          <span class="css-1rvw9cw" @click="downloadAchatAndro">{{$t("message.bottom_android")}}</span>
<!--          <a-tooltip class="dark_theme" placement="right" color="#fff">-->
<!--            <template #title>-->
<!--              <div style="color: #252b4d;" >-->
<!--                <div style="font-size: 18px;text-align: center;margin: 6px 0 12px 0;">{{$t("message.scalCode")}}</div>-->
<!--                <div>-->
<!--                  <img src="@/assets/image/QRCode.png">-->
<!--                  <img :src="store.state.imgUrl">-->
<!--                </div>-->
<!--              </div>-->
<!--            </template>-->
            <span class="css-1rvw9cw" @click="downloadAchatIos()">{{$t("message.bottom_iPhone")}}</span>
<!--          </a-tooltip>-->
          <span class="css-1rvw9cw" @click="downloadAchatMac">{{$t("message.bottom_mac")}}</span>
<!--          <span class="css-1rvw9cw">Mac (M1)</span>-->
          <span class="css-1rvw9cw" @click="downloadAchatWind">{{$t("message.bottom_Windows")}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import {downloadAchatMac,downloadAchatWind,downloadAchatAndro,clickMenu,downloadAchatIos} from "@/utils/downLoadApp";
import {useStore} from "vuex";

const store = useStore();

</script>

<style scoped>
@import "../assets/css/bottom.css";
</style>