import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css'; // 确保导入样式
import i18n from '././locales/index'; // 确保路径正确



const app = createApp(App);
app.use(store);
app.use(router);
app.use(Antd);
app.use(i18n);
app.mount('#app');
