<template>
  <div class="langBg" @click.stop="toggleSelector()" v-show="langSelected">
    <div class="css-1oi06vm MuiBackdrop-invisible css-1oi06vm" style="opacity: 1;transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);"></div>
    <div class="langChange" @click.stop>
      <div class="css-1oj13k7">{{$t("message.langChange")}}</div>
      <div class="css-1dpcpwo">
        <div class="css-6sa5p3  MuiListItem-button MuiButtonBase-root" @click="changeLanguage('en')" :class="{ 'active': isEnglish }">
          <img src="@/assets/image/meguo.png" class="css-r064lk">
          <div class="css-xlf8v1">
            <span class="css-1xwngcv">{{$t("message.en")}}</span>
          </div>
        </div>
        <div class="css-6sa5p3  MuiListItem-button MuiButtonBase-root" @click="changeLanguage('zh-Hant')" :class="{ 'active': isHant }">
          <img src="@/assets/image/zhong.png" class="css-r064lk">
          <div class="css-xlf8v1">
            <span class="css-1xwngcv">{{$t("message.zh-Hant")}}</span>
          </div>
        </div>
        <div class="css-6sa5p3  MuiListItem-button MuiButtonBase-root" @click="changeLanguage('zh')" :class="{ 'active': isZh }">
          <img src="@/assets/image/zhong.png" class="css-r064lk">
          <div class="css-xlf8v1">
            <span class="css-1xwngcv">{{$t("message.zh")}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="headerWrapper css-181n35p " :class="{'fixed' : isScroll}">
    <div class="MuiContainer-root MuiContainer-maxWidthLg css-twwn0w">
      <div class="MuiBox-root css-70qvj9">
        <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover  active css-cd24pg" aria-label="Ant Messenger" href="/">
          <div class="MuiBox-root css-xipxdk"><img src="@/assets/image/logo-icon.png" alt="">
          </div>
          <span class="TitleWrapper css-2bqqqw" v-if="isScroll" style="color: black">Chat Base</span>
          <span class="TitleWrapper css-2bqqqw" v-else>Chat Base</span>
        </a>
<!--         @click="langSelected=true"-->
        <div class="MuiBox-root css-1g18a13">
          <div class="languageSwitcher MuiBox-root css-0">
            <span v-if="isScroll" style="color: black">{{$t("message.currentLang")}}</span>
            <span v-else>{{$t("message.currentLang")}}</span>
<!--            <span class="iconfont icon-bottom">-->
<!--              <img src="@/assets/image/icon-arrow.svg" v-if="isScroll"  style="width: 14px;display: flex;flex-direction: column;margin-left: 2px;align-items: center;transform: rotate(-90deg)">-->
<!--              <img src="@/assets/image/icon-arrow-night.png" v-else style="width: 16px;display: flex;flex-direction: column;margin-left: 2px;align-items: center;">-->
<!--            </span>-->
          </div>
          <div class="moreMenu" @click="showMenu()">
            <span class="iconzebra-moreMenu iconzebra" v-if="show===false">
              <img src="@/assets/image/img_h5/menu-dots.svg" v-if="isScroll" style="margin-left: 1px;width: 18px;">
              <img src="@/assets/image/img_h5/moreMenu.svg" v-else style="margin-left: 3px">
            </span>
            <span class="iconzebra-moreMenu iconzebra" v-else>
              <img src="@/assets/image/img_h5/cancle_black.svg" v-if="isScroll" style="width: 18px;">
              <img src="@/assets/image/img_h5/cancle.svg" v-else style="width: 18px;">
            </span>
          </div>
        </div>
      </div>
      <div class="more-menu MuiBox-root css-0" :class="{'show' : show}">
        <div class="more-menu-item MuiBox-root css-0" :class="{'active' : selectMenu('1')}" @click="menuIndex='1'">{{$t("message.menu_1")}}</div>
        <div class="more-menu-item MuiBox-root css-0" :class="{'active' : selectMenu('5')}" @click="menuIndex='5';clickMenu()">{{$t("message.menu_5")}}</div>
<!--        <div class="more-menu-item MuiBox-root css-0" :class="{'active' : selectMenu('2')}" @click="menuIndex='2'">{{$t("message.menu_2")}}</div>-->
<!--        <div class="more-menu-item MuiBox-root css-0" :class="{'active' : selectMenu('3')}" @click="menuIndex='3'">{{$t("message.menu_3")}}</div>-->
<!--        <div class="more-menu-item MuiBox-root css-0" :class="{'active' : selectMenu('4')}" @click="menuIndex='4'">{{$t("message.menu_4")}}</div>-->
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, onUnmounted, ref} from "vue";
import {useStore} from "vuex";
import {clickMenu} from "@/utils/downLoadApp";
import {useI18n} from "vue-i18n";


const {locale} = useI18n()
const store=useStore()
const menuIndex=ref('1')
const show=ref(false)
const langSelected = ref(false);
const isScroll = ref(false);


// 创建一个响应式引用来存储当前的滚轮位置
const scrollPosition = ref(0);

// 定义一个函数来处理滚动事件
const handleScroll = () => {
  // 更新滚轮位置
  scrollPosition.value = window.scrollY || window.pageYOffset; // 兼容性考虑
  if(scrollPosition.value>1){
    isScroll.value=true
  }else {
    isScroll.value=false
  }
};

// 组件挂载后添加滚动事件监听器
onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

// 组件卸载前移除滚动事件监听器
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

function selectMenu (val){
  if(menuIndex.value===val){
    return true
  }
}


function showMenu(){
  show.value=!show.value
}


const isEnglish = computed(() => {
  return store.state.lang === 'en'; // 假设当语言是英语时，添加active类
});

const isHant = computed(() => {
  return store.state.lang === 'zh-Hant'; // 假设当语言是英语时，添加active类
});

const isZh = computed(() => {
  return store.state.lang === 'zh'; // 假设当语言是英语时，添加active类
});


function changeLanguage(val){
  store.commit('setLang',val)
  locale.value=store.state.lang
  langSelected.value=false
}

function toggleSelector() {
  langSelected.value = !langSelected.value;
}

</script>

<style scoped>
@import "./../../assets/css/topHeader_h5.css";
</style>