<template>
  <div class="MainPart css-1co2tbr"  style="background: rgb(8, 12, 37)">
    <div class="MuiContainer-root MuiContainer-maxWidthLg mainContainer css-1fjb92j">
      <div class="main-content MuiBox-root css-1cmazxv">
        <div class="mainLeftPCORPhone MuiBox-root css-134afa">
          <div class="MainTitleContent css-3l1e46" style="white-space: normal; line-height: 120%;color: #b5c1ff">{{$t("message.title")}}</div>
          <div class="MainTitleDescContent css-7g46bb">{{$t("message.title_dec_1")}}</div>
          <div class="MainTitleDescContent css-7g46bb">{{$t("message.title_dec_2")}}</div>
          <a href="#downloadContent">
            <button class="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root mainDownloadButton css-5jykr7" tabindex="0" type="button">
              <img src="@/assets/image/download.svg">{{$t("message.downLoadAchat")}}
            </button>
          </a>
        </div>
        <div class="mainRightPCORPhone MuiBox-root css-2rff7o">
          <img class="main-pc" src="@/assets/image/theme/top_img_desk_night@3x.png" alt="">
          <img class="main-phone" src="@/assets/image/theme/top_img_phone_night@3x.png" alt="" style="width: 23rem;height: 18.0625rem;position: absolute;left: unset;top: unset;right: -140px;bottom: 7.2rem;">
          <div class="quto-message message-1 MuiBox-root css-0">
            <span>{{$t("message.quto_message_1")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_1_time")}}</span>
          </div>
          <div class="quto-message message-2 MuiBox-root css-0">
            <span>{{$t("message.quto_message_2")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_2_time")}}</span>
          </div>
          <div class="quto-message message-3 MuiBox-root css-0">
            <span>{{$t("message.quto_message_3")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_3_time")}}</span>
          </div>
          <div class="quto-message message-4 MuiBox-root css-0">
            <span>{{$t("message.quto_message_4")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_4_time")}}</span>
          </div>
          <div class="quto-message message-5 MuiBox-root css-0">
            <span>{{$t("message.quto_message_5")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_5_time")}}</span>
          </div>
          <div class="quto-message message-6 MuiBox-root css-0">
            <span>{{$t("message.quto_message_6")}}</span>
            <span class="quto-message-time">{{$t("message.quto_message_6_time")}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <application-traite_h5></application-traite_h5>
  <div class="Download css-n6tcva" id="downloadContent" style="background: rgb(8, 12, 37);margin-top: -1px;">
    <div class="MuiContainer-root MuiContainer-maxWidthLg DownloadContainer css-1c0oca0">
      <div class="DownloadTitle MuiBox-root css-1d5ftub">
        <span class="BoldFontTitleStyle css-1xgifju light-theme" style="color: white">{{$t("message.downLoadAchat")}}</span>
      </div>
      <div class="content-center MuiBox-root css-148e9u8">
        <div class="MuiBox-root css-0">
          <div class="down-content-image MuiBox-root css-6e4k46">
            <div class="down-img MuiBox-root css-22hgpa">
              <img src="@/assets/image/home/down_img_phone_night@3x.png" alt="">
            </div>
          </div>
          <div class="download-button-content MuiBox-root css-1tnr7w">
            <button class="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root download-button css-15ezw6l" tabindex="0" type="button" @click="downloadAchatAndro">{{$t("message.android")}}</button>
<!--            <a-tooltip class="dark_theme" placement="top" color="#fff">-->
<!--              <template #title>-->
<!--                <div style="color: #252b4d;" >-->
<!--                  <div style="font-size: 18px;text-align: center;margin: 6px 0 12px 0;">{{$t("message.scalCode")}}</div>-->
<!--                  <div>-->
<!--                    <img src="@/assets/image/QRCode.png">-->
<!--                    <img :src="store.state.imgUrl">-->
<!--                  </div>-->
<!--                </div>-->
<!--              </template>-->
              <button class="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root download-button css-15ezw6l" tabindex="0" type="button" @click="downloadAchatIos()">{{$t("message.iPhone")}}</button>
<!--            </a-tooltip>-->
            </div>
        </div>
        <div class="MuiBox-root css-0">
          <div class="down-content-image MuiBox-root css-6e4k46">
            <div class="down-img MuiBox-root css-18nidnv">
              <img src="@/assets/image/home/down_img_mac_night@3x.png" alt="">
            </div>
          </div>
          <div class="MuiBox-root css-1a14rma">
            <button class="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root download-button css-15ezw6l" tabindex="0" type="button" @click="downloadAchatMac">{{$t("message.Mac")}}</button>
          </div>
          <div class="download-desc MuiBox-root css-1qk3f4n">
<!--            <a href="javascript:;">下载Mac（M1）</a>-->
          </div>/
          <div class="download-desc MuiBox-root css-1lyfx7r" style="color: white">{{$t("message.scalLogin")}}</div>
          <div class="MuiBox-root css-1g31uvb light-theme" style="color: white">{{$t("message.ortherType")}}</div>
          <div class="download-desc MuiBox-root css-1qk3f4n">
            <a href="javascript:;" @click="downloadAchatWind">{{$t("message.Windows")}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="css-ky9xmf checkTheme">
    <img src="@/assets/image/moon_icon.svg" style="width: 100%;height: 100%;transform: scale(1.3);" v-if="store.state.theme==='sun'" @click="changeTheme('moon')">
    <img src="@/assets/image/sun_icon.png" style="width: 100%;height: 100%;transform: scale(1.3);" v-else @click="changeTheme('sun')">
  </div>
</template>

<script setup>
import ApplicationTraite_h5 from "@/components/applicationTraite_h5.vue";
import {useStore} from "vuex";
import {downloadAchatMac,downloadAchatWind,downloadAchatAndro,downloadAchatIos} from "@/utils/downLoadApp";
const store = useStore();

function changeTheme(val){
  store.state.theme=val
}
</script>

<style scoped>
@import "./../../assets/css/homeView_h5.css";
</style>