import { createStore } from 'vuex'

export default createStore({
  state(){
    return{
      theme:'sun',
      lang:'zh',
      webPage:false,
      imgUrl:null
    }
  },
  getters: {
  },
  mutations: {
    setTheme(state,theme){
      state.theme=theme
    },
    setLang(state,lang){
      state.lang=lang
    },
    setWebPage(state,webPage){
      state.webPage=webPage
    },
    setImgUrl(state,imgUrl){
      state.imgUrl=imgUrl
    },
  },
  actions: {
  },
  modules: {
  }
})
