import { createI18n } from 'vue-i18n';
// 动态加载翻译文件
import enMessages from '../locales/en.json';
import zhMessages from '../locales/zh.json';
import zhHantMessages from '../locales/zh-Hant.json';

const messages = {
    en: enMessages,
    zh: zhMessages,
    'zh-Hant': zhHantMessages
};

export default createI18n({
    locale: 'zh', // 默认语言
    messages
});
