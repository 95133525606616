<template>
  <div class="css-n6tcva AppTrait" :style="{background: store.state.theme==='sun' ? '#ffffff' : 'rgb(8, 12, 37)' }" style="margin-top: -1px;">
    <div class="css-1c0oca0">
      <div class="MuiBox-root css-zdw6ar">
        <span class="BoldFontTitleStyle css-1xgifju" :style="{color: store.state.theme==='sun' ? '#000' : '#fff' }">
          <span v-if="store.state.lang==='zh'">应用特点</span>
          <span v-if="store.state.lang==='en'">Application characteristics</span>
          <span v-if="store.state.lang==='zh-Hant'">應用特點</span>
        </span>
      </div>
      <div class="AppTraitList MuiBox-root css-17gw5wb">
        <div class="AppTraitItem MuiBox-root css-fzwid6" v-for="(item,index) in features" :key="index">
          <div class="AppTraitItemImg MuiBox-root css-1u7brxp">
            <img :src="item.image" alt="Multiple deployment modes">
            <div class="AppTraitItemName MuiBox-root css-1yruo8d">
              <span class="BoldFontNameStyle css-jst8z6" :style="{color: store.state.theme==='sun' ? '#353e6f' : '#fff' }">{{ item.title }}</span>
            </div>
          </div>
          <div class="MuiBox-root ">
              <span class="AppTraitItemDesc css-1dtbcw9">
                {{item.description}}
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useStore} from "vuex";
import {reactive, watch} from "vue";

const store = useStore();
let features = reactive([])

const  features_light = reactive([
  {
    image: require('@/assets/image/application/bushu.png'),
    alt: 'Multiple deployment modes',
    title: '多种部署方式',
    description: '支持私有化部署和云托管，实现了“用户信息、通信内容、数据文件”完全处于自主安全可控'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple.png'),
    alt: 'Multiple deployment modes',
    title: '消息端到端加密',
    description: '采用国际主流的端到端加密方式保证消息安全、支持阅后即焚、双向删除不留痕'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(4).png'),
    alt: 'Multiple deployment modes',
    title: '多设备登录',
    description: '支持5台设备同时登录，实时同步聊天记录'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(2).png'),
    alt: 'Multiple deployment modes',
    title: '超级群组',
    description: '支持10万人在线交流，群消息经过TLS加密'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(1).png'),
    alt: 'Multiple deployment modes',
    title: '聊天强大',
    description: '支持文字、语音、视频、图片、文件、位置、表情、名片分享、通话'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(3).png'),
    alt: 'Multiple deployment modes',
    title: '隐私保护',
    description: '可以隐藏您的电话号码和用户ID，给应用设置应用锁'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(6).png'),
    alt: 'Multiple deployment modes',
    title: '云盘存储',
    description: '上传云盘的文件高度加密，不限容量不限时间'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(7).png'),
    alt: 'Multiple deployment modes',
    title: '开放的',
    description: '开源的聊天应用，您可以免费的使用和查看源代码'
  },
])

const  features_light_en = reactive([
  {
    image: require('@/assets/image/application/bushu.png'),
    alt: 'Multiple deployment modes',
    title: 'Multiple deployment modes',
    description: 't supports private deployment and cloud hosting, realizing that "user information, communication content, and data files" are fully autonomous, secure and controllable'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple.png'),
    alt: 'Multiple deployment modes',
    title: 'Messages are encrypted end-to-end',
    description: 'The international mainstream end-to-end encryption method is adopted to ensure message security, support instant burning after reading, two-way deletion without leaving traces'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(4).png'),
    alt: 'Multiple deployment modes',
    title: 'Multi-device login',
    description: 'Supports simultaneous login of five devices and real-time chat synchronization'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(2).png'),
    alt: 'Multiple deployment modes',
    title: 'Super Group',
    description: 'Supports online communication with 100,000 people, and group messages are encrypted through TLS'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(1).png'),
    alt: 'Multiple deployment modes',
    title: 'Chat power',
    description: 'Support text, voice, video, picture, file, location, expression, business card sharing, call'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(3).png'),
    alt: 'Multiple deployment modes',
    title: 'Privacy protection',
    description: 'You can hide your phone number and user ID and set an app lock on your app'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(6).png'),
    alt: 'Multiple deployment modes',
    title: 'Cloud disk storage',
    description: 'The files uploaded to the cloud disk are highly encrypted and have no limit on capacity or time'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(7).png'),
    alt: 'Multiple deployment modes',
    title: 'Open',
    description: 'Open source chat application, you can use and view the source code for free'
  },
])

const  features_light_hant = reactive([
  {
    image: require('@/assets/image/application/bushu.png'),
    alt: 'Multiple deployment modes',
    title: '多種部署管道',
    description: '支持私有化部署和雲託管,實現了“用戶資訊、通信內容、資料檔案”完全處於自主安全可控'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple.png'),
    alt: 'Multiple deployment modes',
    title: '消息端到端加密',
    description: '採用國際主流的端到端加密管道保證消息安全、支持閱後即焚、雙向删除不留痕'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(4).png'),
    alt: 'Multiple deployment modes',
    title: '多設備登入',
    description: '支持5臺設備同時登入,實时同步聊天記錄'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(2).png'),
    alt: 'Multiple deployment modes',
    title: '超級群組',
    description: '支持10萬人線上交流,群消息經過TLS加密'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(1).png'),
    alt: 'Multiple deployment modes',
    title: '聊天强大',
    description: '支持文字、語音、視頻、圖片、檔案、位置、表情、名片分享、通話'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(3).png'),
    alt: 'Multiple deployment modes',
    title: '隱私保護',
    description: '可以隱藏您的電話號碼和用戶ID,給應用設定應用鎖'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(6).png'),
    alt: 'Multiple deployment modes',
    title: '雲盤存儲',
    description: '上傳雲盤的檔案高度加密,不限容量不限時間'
  },
  {
    image: require('@/assets/image/application/iconicon_home_simple(7).png'),
    alt: 'Multiple deployment modes',
    title: '開放的',
    description: '開源的聊天應用,您可以免費的使用和查看原始程式碼'
  },
])

const  features_dark = reactive([
  {
    image: require('@/assets/image/application/bushu_night.png'),
    alt: 'Multiple deployment modes',
    title: '多种部署方式',
    description: '支持私有化部署和云托管，实现了“用户信息、通信内容、数据文件”完全处于自主安全可控'
  },
  {
    image: require('@/assets/image/application/simple_black.png'),
    alt: 'Multiple deployment modes',
    title: '消息端到端加密',
    description: '采用国际主流的端到端加密方式保证消息安全、支持阅后即焚、双向删除不留痕'
  },
  {
    image: require('@/assets/image/application/simple_black(4).png'),
    alt: 'Multiple deployment modes',
    title: '多设备登录',
    description: '支持5台设备同时登录，实时同步聊天记录'
  },
  {
    image: require('@/assets/image/application/simple_black(2).png'),
    alt: 'Multiple deployment modes',
    title: '超级群组',
    description: '支持10万人在线交流，群消息经过TLS加密'
  },
  {
    image: require('@/assets/image/application/simple_black(1).png'),
    alt: 'Multiple deployment modes',
    title: '聊天强大',
    description: '支持文字、语音、视频、图片、文件、位置、表情、名片分享、通话'
  },
  {
    image: require('@/assets/image/application/simple_black(3).png'),
    alt: 'Multiple deployment modes',
    title: '隐私保护',
    description: '可以隐藏您的电话号码和用户ID，给应用设置应用锁'
  },
  {
    image: require('@/assets/image/application/simple_black(6).png'),
    alt: 'Multiple deployment modes',
    title: '云盘存储',
    description: '上传云盘的文件高度加密，不限容量不限时间'
  },
  {
    image: require('@/assets/image/application/simple_black(7).png'),
    alt: 'Multiple deployment modes',
    title: '开放的',
    description: '开源的聊天应用，您可以免费的使用和查看源代码'
  },
])

const  features_dark_en = reactive([
  {
    image: require('@/assets/image/application/bushu_night.png'),
    alt: 'Multiple deployment modes',
    title: 'Multiple deployment modes',
    description: 't supports private deployment and cloud hosting, realizing that "user information, communication content, and data files" are fully autonomous, secure and controllable'
  },
  {
    image: require('@/assets/image/application/simple_black.png'),
    alt: 'Multiple deployment modes',
    title: 'Messages are encrypted end-to-end',
    description: 'The international mainstream end-to-end encryption method is adopted to ensure message security, support instant burning after reading, two-way deletion without leaving traces'
  },
  {
    image: require('@/assets/image/application/simple_black(4).png'),
    alt: 'Multiple deployment modes',
    title: 'Multi-device login',
    description: 'Supports simultaneous login of five devices and real-time chat synchronization'
  },
  {
    image: require('@/assets/image/application/simple_black(2).png'),
    alt: 'Multiple deployment modes',
    title: 'Super Group',
    description: 'Supports online communication with 100,000 people, and group messages are encrypted through TLS'
  },
  {
    image: require('@/assets/image/application/simple_black(1).png'),
    alt: 'Multiple deployment modes',
    title: 'Chat power',
    description: 'Support text, voice, video, picture, file, location, expression, business card sharing, call'
  },
  {
    image: require('@/assets/image/application/simple_black(3).png'),
    alt: 'Multiple deployment modes',
    title: 'Privacy protection',
    description: 'You can hide your phone number and user ID and set an app lock on your app'
  },
  {
    image: require('@/assets/image/application/simple_black(6).png'),
    alt: 'Multiple deployment modes',
    title: 'Cloud disk storage',
    description: 'The files uploaded to the cloud disk are highly encrypted and have no limit on capacity or time'
  },
  {
    image: require('@/assets/image/application/simple_black(7).png'),
    alt: 'Multiple deployment modes',
    title: 'Open',
    description: 'Open source chat application, you can use and view the source code for free'
  },
])

const  features_dark_hant = reactive([
  {
    image: require('@/assets/image/application/bushu_night.png'),
    alt: 'Multiple deployment modes',
    title: '多種部署管道',
    description: '支持私有化部署和雲託管,實現了“用戶資訊、通信內容、資料檔案”完全處於自主安全可控'
  },
  {
    image: require('@/assets/image/application/simple_black.png'),
    alt: 'Multiple deployment modes',
    title: '消息端到端加密',
    description: '採用國際主流的端到端加密管道保證消息安全、支持閱後即焚、雙向删除不留痕'
  },
  {
    image: require('@/assets/image/application/simple_black(4).png'),
    alt: 'Multiple deployment modes',
    title: '多設備登入',
    description: '支持5臺設備同時登入,實时同步聊天記錄'
  },
  {
    image: require('@/assets/image/application/simple_black(2).png'),
    alt: 'Multiple deployment modes',
    title: '超級群組',
    description: '支持10萬人線上交流,群消息經過TLS加密'
  },
  {
    image: require('@/assets/image/application/simple_black(1).png'),
    alt: 'Multiple deployment modes',
    title: '聊天强大',
    description: '支持文字、語音、視頻、圖片、檔案、位置、表情、名片分享、通話'
  },
  {
    image: require('@/assets/image/application/simple_black(3).png'),
    alt: 'Multiple deployment modes',
    title: '隱私保護',
    description: '可以隱藏您的電話號碼和用戶ID,給應用設定應用鎖'
  },
  {
    image: require('@/assets/image/application/simple_black(6).png'),
    alt: 'Multiple deployment modes',
    title: '雲盤存儲',
    description: '上傳雲盤的檔案高度加密,不限容量不限時間'
  },
  {
    image: require('@/assets/image/application/simple_black(7).png'),
    alt: 'Multiple deployment modes',
    title: '開放的',
    description: '開源的聊天應用,您可以免費的使用和查看原始程式碼'
  },
])

function getFeatures ()  {
  if(store.state.lang==='zh'){
    if (store.state.theme === 'sun') {
      features= features_light;
    } else {
      features= features_dark;
    }
  }else if(store.state.lang==='en'){
    if (store.state.theme === 'sun') {
      features= features_light_en;
    } else {
      features= features_dark_en;
    }
  }else if(store.state.lang==='zh-Hant'){
    if (store.state.theme === 'sun') {
      features= features_light_hant;
    } else {
      features= features_dark_hant;
    }
  }
}

watch(()=>store.state.lang,(newLang)=>{
  if (newLang){
    getFeatures()
  }
})

getFeatures()

</script>

<style scoped>

</style>