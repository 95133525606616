<template>
  <div class="langBg" @click.stop="toggleSelector()" v-show="langSelected">
    <div class="css-1oi06vm MuiBackdrop-invisible css-1oi06vm" style="opacity: 1;transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);"></div>
    <div class="langChange" @click.stop>
      <div class="css-1oj13k7">{{$t("message.langChange")}}</div>
      <div class="css-1dpcpwo">
        <div class="css-6sa5p3  MuiListItem-button MuiButtonBase-root" @click="changeLanguage('en')" :class="{ 'active': isEnglish }">
          <img src="@/assets/image/meguo.png" class="css-r064lk">
          <div class="css-xlf8v1">
            <span class="css-1xwngcv">{{$t("message.en")}}</span>
          </div>
        </div>
        <div class="css-6sa5p3  MuiListItem-button MuiButtonBase-root" @click="changeLanguage('zh-Hant')" :class="{ 'active': isHant }">
          <img src="@/assets/image/zhong.png" class="css-r064lk">
          <div class="css-xlf8v1">
            <span class="css-1xwngcv">{{$t("message.zh-Hant")}}</span>
          </div>
        </div>
        <div class="css-6sa5p3  MuiListItem-button MuiButtonBase-root" @click="changeLanguage('zh')" :class="{ 'active': isZh }">
          <img src="@/assets/image/zhong.png" class="css-r064lk">
          <div class="css-xlf8v1">
            <span class="css-1xwngcv">{{$t("message.zh")}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="topHeader">
    <div style="width: 65%;margin: 0 auto;display: flex;justify-content: space-between;">
<!--      <a-tooltip title="Achat" placement="bottom" color="#fff">-->
        <div class="css-cd24pg">
          <div class="css-xipxdk MuiBox-root">
            <img src="@/assets/image/logo-icon.png">
          </div>
          <span class="TitleWrapper css-2bqqqw">Chat Base</span>
        </div>
<!--      </a-tooltip>-->
      <div class="css-vnz3w4 MuiBox-root">
        <div class="css-c19i30">
          <div class="ButtonWrapper css-1erxk2b" @click="changePage(1)" :style="{color:activeIndex===1 ?  '#1aa4ec'  :  '#565656' }">{{$t("message.menu_1")}}</div>
          <div class="ButtonWrapper css-1erxk2b" @click="changePage(5);clickMenu()" :style="{color:activeIndex===5 ?  '#1aa4ec'  :  '#565656' }">{{$t("message.menu_5")}}</div>
<!--          <div class="ButtonWrapper css-1erxk2b" @click="changePage(2)" :style="{color:activeIndex===2 ?  '#1aa4ec'  :  '#565656' }">{{$t("message.menu_2")}}</div>-->
<!--          <div class="ButtonWrapper css-1erxk2b" @click="changePage(3)" :style="{color:activeIndex===3 ?  '#1aa4ec'  :  '#565656' }">{{$t("message.menu_3")}}</div>-->
<!--          <div class="ButtonWrapper css-1erxk2b" @click="changePage(4)" :style="{color:activeIndex===4 ?  '#1aa4ec'  :  '#565656' }">{{$t("message.menu_4")}}</div>-->
          <div class="css-1g18a13 MuiBox-root">
            <a-tooltip class="language" placement="bottom">
<!--              关闭tip弹窗-->
<!--              <template #title><div style="color: white">{{$t("message.langChange")}}</div></template>-->
<!--              关闭切换语言按钮-->
<!--              <div class="languageSwitcher" @click="langSelected=true">-->
              <div class="languageSwitcher">
                {{$t("message.currentLang")}}
<!--                <span><img src="@/assets/image/icon-arrow.svg" style="width: 14px;transform: rotate(-90deg);display: flex;flex-direction: column;margin-left: 6px;align-items: center"></span>-->
              </div>
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {clickMenu} from "@/utils/downLoadApp";
import {useI18n} from "vue-i18n";


const activeIndex=ref(1)
const langSelected = ref(false);


const store=useStore()
const {locale} = useI18n()

const isEnglish = computed(() => {
  return store.state.lang === 'en'; // 假设当语言是英语时，添加active类
});

const isHant = computed(() => {
  return store.state.lang === 'zh-Hant'; // 假设当语言是英语时，添加active类
});

const isZh = computed(() => {
  return store.state.lang === 'zh'; // 假设当语言是英语时，添加active类
});

function changePage(val){
  activeIndex.value=val
  if(val===1){
    console.log('dd');
  }
}

function changeLanguage(val){
  store.commit('setLang',val)
  locale.value=store.state.lang
  langSelected.value=false
}

function toggleSelector() {
  langSelected.value = !langSelected.value;
}

</script>

<style scoped>
@import "../assets/css/topHeader.css";
</style>