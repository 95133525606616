<template>
  <div v-if="screenWidth>600">
    <top-header v-if="store.state.theme==='sun'"></top-header>
    <top-header_moon v-else></top-header_moon>
  </div>
  <div v-if="screenWidth<600">
    <top-header_h5 v-if="store.state.theme==='sun'"></top-header_h5>
    <top-header_h5_moon v-else></top-header_h5_moon>
  </div>

  <router-view/>
  <div v-if="screenWidth>600">
    <bottom-view v-if="store.state.theme==='sun'"></bottom-view>
    <bottom-view_moon v-else></bottom-view_moon>
  </div>
  <div v-if="screenWidth<600">
    <bottom-view_h5 v-if="store.state.theme==='sun'"></bottom-view_h5>
    <bottom-view_h5_moon v-else></bottom-view_h5_moon>
  </div>

</template>

<style lang="less">
@import "assets/css/base.css";
@import "assets/css/ant_commen.css";
@import "assets/css/home.css";
</style>
<script setup>
import TopHeader from "@/components/topHeader.vue";
import BottomView from "@/components/bottomView.vue";
import TopHeader_moon from "@/components/topHeader_moon.vue";
import { useStore } from 'vuex';
import BottomView_moon from "@/components/bottomView_moon.vue";
import { onMounted, onUnmounted, ref, watch} from "vue";
import TopHeader_h5 from "@/components/topHeader_h5/topHeader_h5.vue";
import BottomView_h5 from "@/components/bottomView_h5/bottomView_h5.vue";
import TopHeader_h5_moon from "@/components/topHeader_h5/topHeader_h5_moon.vue";
import BottomView_h5_moon from "@/components/bottomView_h5/bottomView_h5_moon.vue";
import {downloadAchatIos} from "@/utils/downLoadApp";
const store = useStore();
let screenWidth=ref()


// 监听窗口大小变化并更新 screenWidth
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  // downloadAchatIos()
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});


watch(screenWidth, () => {
  if(screenWidth.value<600){
    store.commit('setWebPage','false')
  }else {
    store.commit('setWebPage','true')
  }
  // 执行其他逻辑...
});

handleResize()

// checkScreenWidth()

</script>