<template>
  <div class="footerContent css-11lpknc" style="background: rgb(8, 12, 37);margin-top: -1px;">
    <div class="smFooterContainer MuiBox-root css-0">
      <ul role="tree" aria-multiselectable="false" class="MuiTreeView-root myTree css-12mehxg" tabindex="0" id="mui-3">
        <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" aria-expanded="false" tabindex="-1" id="mui-3-Ni7L857y-NnPpt-58o5R-Fw762-u079I">
          <div class="css-yahquj MuiTreeItem-content">
            <div class="MuiTreeItem-label"  :style="{background : showMore ? '#25204c' : 'none'}">
              <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button" @click="isShow">
                <div class="MuiListItemText-root css-1cpr62g">
                  <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white">{{$t("message.followMe")}}</span>
                </div>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium item-icon css-vubbuv" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="ExpandMoreIcon" :style="{transform :showMore ? 'rotate(180deg)' : 'rotate(0deg)'}">
                  <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                </svg>
                <span class="MuiTouchRipple-root css-w0pj6f"></span>
              </div>
            </div>
          </div>
          <ul v-show="showMore" class="MuiCollapse-root MuiCollapse-vertical MuiTreeItem-group MuiCollapse-entered css-1xxsnna" role="group" >
            <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
              <div class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb">
                <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" id="mui-3-jXlaUQ12-gvzfT-4qEoq-MWfq8-ANKXs" tabindex="-1">
                  <div class="css-yahquj MuiTreeItem-content">
                    <div class="MuiTreeItem-iconContainer"></div>
                    <div class="MuiTreeItem-label">
                      <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button">
                        <div class="MuiListItemText-root css-1cpr62g">
                          <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white">{{$t("message.menu_1")}}</span>
                        </div>
                        <span class="MuiTouchRipple-root css-w0pj6f"></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" id="mui-3-jXlaUQ12-gvzfT-4qEoq-MWfq8-ANKXs" tabindex="-1">
                  <div class="css-yahquj MuiTreeItem-content">
                    <div class="MuiTreeItem-iconContainer"></div>
                    <div class="MuiTreeItem-label">
                      <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button">
                        <div class="MuiListItemText-root css-1cpr62g">
                          <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white" @click="clickMenu">{{$t("message.menu_5")}}</span>
                        </div>
                        <span class="MuiTouchRipple-root css-w0pj6f"></span>
                      </div>
                    </div>
                  </div>
                </li>
<!--                <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" id="mui-3-0tF90YCM-BU4HK-Q99N3-kCRwJ-2UhQo" tabindex="-1">-->
<!--                  <div class="css-yahquj MuiTreeItem-content">-->
<!--                    <div class="MuiTreeItem-iconContainer"></div>-->
<!--                    <div class="MuiTreeItem-label">-->
<!--                      <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button">-->
<!--                        <div class="MuiListItemText-root css-1cpr62g">-->
<!--                          <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white">企业部署</span>-->
<!--                        </div>-->
<!--                        <span class="MuiTouchRipple-root css-w0pj6f"></span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </li>-->
<!--                <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" id="mui-3-HuBm1Mea-aQ0Y3-1II55-55SN6-8ES33" tabindex="-1">-->
<!--                  <div class="css-yahquj MuiTreeItem-content">-->
<!--                    <div class="MuiTreeItem-iconContainer"></div>-->
<!--                    <div class="MuiTreeItem-label">-->
<!--                      <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button">-->
<!--                        <div class="MuiListItemText-root css-1cpr62g">-->
<!--                          <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white">常见问题</span>-->
<!--                        </div>-->
<!--                        <span class="MuiTouchRipple-root css-w0pj6f"></span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </li>-->
<!--                <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" id="mui-3-UwGaX3TE-Jh0R4-Xcdj2-XJrP7-9d4hg" tabindex="-1">-->
<!--                  <div class="css-yahquj MuiTreeItem-content">-->
<!--                    <div class="MuiTreeItem-iconContainer"></div>-->
<!--                    <div class="MuiTreeItem-label">-->
<!--                      <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button">-->
<!--                        <div class="MuiListItemText-root css-1cpr62g">-->
<!--                          <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white">隐私设置</span>-->
<!--                        </div>-->
<!--                        <span class="MuiTouchRipple-root css-w0pj6f"></span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </li>-->
              </div>
            </div>
          </ul>
        </li>
        <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" aria-expanded="false" tabindex="-1" id="mui-3-74Gi5c27-aCjpx-HMapG-Vhi3Z-647Bk">
          <div class="css-yahquj MuiTreeItem-content">
            <div class="MuiTreeItem-label" :style="{background : downLoad ? '#25204c' : 'none'}">
              <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button" @click="isDownLoad">
                <div class="MuiListItemText-root css-1cpr62g">
                  <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white">{{$t("message.downLoadAchat")}}</span>
                </div>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium item-icon css-vubbuv" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="ExpandMoreIcon" :style="{transform :downLoad ? 'rotate(180deg)' : 'rotate(0deg)'}">
                  <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                </svg>
                <span class="MuiTouchRipple-root css-w0pj6f"></span>
              </div>
            </div>

          </div>
          <ul v-show="downLoad" class="MuiCollapse-root MuiCollapse-vertical MuiTreeItem-group MuiCollapse-entered css-1xxsnna" role="group" style="height: auto;transition: height 3s ease;transition-duration:3000ms" >
            <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
              <div class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb">
                <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" id="mui-3-baG6dFzs-qdko7-I341K-hWxl5-6rAmm" tabindex="-1">
                  <div class="css-yahquj MuiTreeItem-content">
                    <div class="MuiTreeItem-iconContainer"></div>
                    <div class="MuiTreeItem-label">
                      <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button">
                        <div class="MuiListItemText-root css-1cpr62g">
                          <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white" @click="downloadAchatAndro">{{$t("message.bottom_android")}}</span>
                        </div>
                        <span class="MuiTouchRipple-root css-w0pj6f"></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" id="mui-3-EA9vz39B-e03fM-C2w8D-R99wG-1Hhx2" tabindex="-1">
                  <div class="css-yahquj MuiTreeItem-content">
                    <div class="MuiTreeItem-iconContainer"></div>
                    <div class="MuiTreeItem-label">
                      <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button">
                        <div class="MuiListItemText-root css-1cpr62g">
                          <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white" @click="downloadAchatIos()">{{$t("message.bottom_iPhone")}}</span>
                        </div>
                        <span class="MuiTouchRipple-root css-w0pj6f"></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" id="mui-3-i1K5145Y-NhH8W-kN5Tw-xt23l-dl83u" tabindex="-1">
                  <div class="css-yahquj MuiTreeItem-content">
                    <div class="MuiTreeItem-iconContainer"></div>
                    <div class="MuiTreeItem-label">
                      <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button">
                        <div class="MuiListItemText-root css-1cpr62g">
                          <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white" @click="downloadAchatMac">{{$t("message.bottom_mac")}}</span>
                        </div>
                        <span class="MuiTouchRipple-root css-w0pj6f"></span>
                      </div>
                    </div>
                  </div>
                </li>
<!--                <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" id="mui-3-x7194JTG-RULB2-Zad1c-TSqeP-PTbNC" tabindex="-1">-->
<!--                  <div class="css-yahquj MuiTreeItem-content">-->
<!--                    <div class="MuiTreeItem-iconContainer"></div>-->
<!--                    <div class="MuiTreeItem-label">-->
<!--                      <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button">-->
<!--                        <div class="MuiListItemText-root css-1cpr62g">-->
<!--&lt;!&ndash;                          <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white">Mac (M1)</span>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <span class="MuiTouchRipple-root css-w0pj6f"></span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </li>-->
                <li class="MuiTreeItem-root css-11tpb2v" role="treeitem" id="mui-3-T5JGn2li-u9M46-5B5IV-K2uT1-8EgWP" tabindex="-1">
                  <div class="css-yahquj MuiTreeItem-content">
                    <div class="MuiTreeItem-iconContainer"></div>
                    <div class="MuiTreeItem-label">
                      <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-dpiah1" tabindex="0" role="button">
                        <div class="MuiListItemText-root css-1cpr62g">
                          <span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1xwngcv" style="color: white" @click="downloadAchatWind">{{$t("message.bottom_Windows")}}</span>
                        </div>
                        <span class="MuiTouchRipple-root css-w0pj6f"></span>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </ul>
        </li>
      </ul>
      <div class="footerZebraMessenger MuiBox-root css-0">
        <div class="footeLogo MuiBox-root css-0">
          <img src="@/assets/image/logo-icon.png" alt="">
        </div>
        <div class="MuiBox-root css-0">
          <span class="css-aqo0mr light-theme" style="font-weight: 700; margin-bottom: 4px;color: white">Chat Base</span>
        </div>
        <span class="footDesc css-1rvw9cw">{{$t("message.bottom_title")}}</span>
<!--        <span class="css-1rvw9cw">联系我们：Postmaster@mail.antmessager.com</span>-->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref} from "vue";
import {downloadAchatMac,downloadAchatWind,downloadAchatAndro,clickMenu,downloadAchatIos} from "@/utils/downLoadApp";
const showMore=ref(false)
const downLoad=ref(false)
function isShow(){
  showMore.value=!showMore.value
}

function isDownLoad(){
  downLoad.value=!downLoad.value
}


</script>

<style scoped>
@import "../../assets/css/bottomView_h5.css";
</style>